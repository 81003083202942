@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-Black.woff2') format('woff2'),
    url('/fonts/inter/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-SemiBold.woff2') format('woff2'),
    url('/fonts/inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
    url('/fonts/inter/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-Bold.woff2') format('woff2'),
    url('/fonts/inter/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-Medium.woff2') format('woff2'),
    url('/fonts/inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-Regular.woff2') format('woff2'),
    url('/fonts/inter/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('/fonts/inter/Inter-Light.woff2') format('woff2'),
    url('/fonts/inter/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: QuickStarter;
  src: url('/fonts/QuickStarter.otf') format('opentype');
}

@font-face {
  font-family: FastTrack;
  src: url('/fonts/FastTrack.otf') format('opentype');
}

@font-face {
  font-family: EtnaSans;
  src: url('/fonts/Etna-Sans-serif.otf') format('opentype');
}

@font-face {
  font-family: LeagueGothic;
  src: url('/fonts/LeagueGothic.otf') format('opentype');
}
